import { default as cookies0Q2mX8WVAYMeta } from "/codebuild/output/src1726576806/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/cookies.vue?macro=true";
import { default as cookies32erYNENQgMeta } from "/codebuild/output/src1726576806/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/cookies.vue?macro=true";
import { default as indexG6aLTWbOSNMeta } from "/codebuild/output/src1726576806/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/index.vue?macro=true";
import { default as legalPbRUyhGd9RMeta } from "/codebuild/output/src1726576806/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/legal.vue?macro=true";
import { default as portfolio_45aboutVuy3x0xz1JMeta } from "/codebuild/output/src1726576806/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/portfolio-about.vue?macro=true";
import { default as portfolio_45feesaz2tE0YKhwMeta } from "/codebuild/output/src1726576806/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/portfolio-fees.vue?macro=true";
import { default as portfolio_45investorswioRW9Z3CHMeta } from "/codebuild/output/src1726576806/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/portfolio-investors.vue?macro=true";
import { default as portfolio_45issuers2X4qy36jwmMeta } from "/codebuild/output/src1726576806/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/portfolio-issuers.vue?macro=true";
import { default as portfolio_45membersxPTIcFu8UvMeta } from "/codebuild/output/src1726576806/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/portfolio-members.vue?macro=true";
import { default as data_45center_45socimitT60tAareIMeta } from "/codebuild/output/src1726576806/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/portfolio-news/data-center-socimi.vue?macro=true";
import { default as indexy4VX1TPt8yMeta } from "/codebuild/output/src1726576806/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/portfolio-news/index.vue?macro=true";
import { default as transformation_45of_45an_45edav_45into_45a_45reitmRP6YUUCq5Meta } from "/codebuild/output/src1726576806/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/portfolio-news/transformation-of-an-edav-into-a-reit.vue?macro=true";
import { default as portfolio_45partners4bEb11UAEpMeta } from "/codebuild/output/src1726576806/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/portfolio-partners.vue?macro=true";
import { default as privacyXBWjucdrjlMeta } from "/codebuild/output/src1726576806/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/privacy.vue?macro=true";
import { default as indexX1pLTLGxhDMeta } from "/codebuild/output/src1726576806/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/index.vue?macro=true";
import { default as legal8qcXzlbQRZMeta } from "/codebuild/output/src1726576806/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/legal.vue?macro=true";
import { default as portfolio_45aboutZeMo9Ol6EEMeta } from "/codebuild/output/src1726576806/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/portfolio-about.vue?macro=true";
import { default as portfolio_45feesOVEmESp3OIMeta } from "/codebuild/output/src1726576806/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/portfolio-fees.vue?macro=true";
import { default as portfolio_45investors8T4TollaT6Meta } from "/codebuild/output/src1726576806/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/portfolio-investors.vue?macro=true";
import { default as portfolio_45issuers2vOOEzoM0SMeta } from "/codebuild/output/src1726576806/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/portfolio-issuers.vue?macro=true";
import { default as portfolio_45membersSlRUARHQ4oMeta } from "/codebuild/output/src1726576806/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/portfolio-members.vue?macro=true";
import { default as data_45center_45socimiY7IOG57iYeMeta } from "/codebuild/output/src1726576806/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/portfolio-news/data-center-socimi.vue?macro=true";
import { default as indexLqs3rDBXJNMeta } from "/codebuild/output/src1726576806/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/portfolio-news/index.vue?macro=true";
import { default as transformation_45of_45an_45edav_45into_45a_45reit9IZHE2kncGMeta } from "/codebuild/output/src1726576806/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/portfolio-news/transformation-of-an-edav-into-a-reit.vue?macro=true";
import { default as portfolio_45partnersElFrq8P5HCMeta } from "/codebuild/output/src1726576806/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/portfolio-partners.vue?macro=true";
import { default as privacyEFVf0vHk48Meta } from "/codebuild/output/src1726576806/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/privacy.vue?macro=true";
export default [
  {
    name: cookies0Q2mX8WVAYMeta?.name ?? "cookies",
    path: cookies0Q2mX8WVAYMeta?.path ?? "/cookies",
    meta: cookies0Q2mX8WVAYMeta || {},
    alias: cookies0Q2mX8WVAYMeta?.alias || [],
    redirect: cookies0Q2mX8WVAYMeta?.redirect,
    component: () => import("/codebuild/output/src1726576806/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/cookies.vue").then(m => m.default || m)
  },
  {
    name: cookies32erYNENQgMeta?.name ?? "es-cookies",
    path: cookies32erYNENQgMeta?.path ?? "/es/cookies",
    meta: cookies32erYNENQgMeta || {},
    alias: cookies32erYNENQgMeta?.alias || [],
    redirect: cookies32erYNENQgMeta?.redirect,
    component: () => import("/codebuild/output/src1726576806/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/cookies.vue").then(m => m.default || m)
  },
  {
    name: indexG6aLTWbOSNMeta?.name ?? "es",
    path: indexG6aLTWbOSNMeta?.path ?? "/es",
    meta: indexG6aLTWbOSNMeta || {},
    alias: indexG6aLTWbOSNMeta?.alias || [],
    redirect: indexG6aLTWbOSNMeta?.redirect,
    component: () => import("/codebuild/output/src1726576806/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/index.vue").then(m => m.default || m)
  },
  {
    name: legalPbRUyhGd9RMeta?.name ?? "es-legal",
    path: legalPbRUyhGd9RMeta?.path ?? "/es/legal",
    meta: legalPbRUyhGd9RMeta || {},
    alias: legalPbRUyhGd9RMeta?.alias || [],
    redirect: legalPbRUyhGd9RMeta?.redirect,
    component: () => import("/codebuild/output/src1726576806/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/legal.vue").then(m => m.default || m)
  },
  {
    name: portfolio_45aboutVuy3x0xz1JMeta?.name ?? "es-portfolio-about",
    path: portfolio_45aboutVuy3x0xz1JMeta?.path ?? "/es/portfolio-about",
    meta: portfolio_45aboutVuy3x0xz1JMeta || {},
    alias: portfolio_45aboutVuy3x0xz1JMeta?.alias || [],
    redirect: portfolio_45aboutVuy3x0xz1JMeta?.redirect,
    component: () => import("/codebuild/output/src1726576806/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/portfolio-about.vue").then(m => m.default || m)
  },
  {
    name: portfolio_45feesaz2tE0YKhwMeta?.name ?? "es-portfolio-fees",
    path: portfolio_45feesaz2tE0YKhwMeta?.path ?? "/es/portfolio-fees",
    meta: portfolio_45feesaz2tE0YKhwMeta || {},
    alias: portfolio_45feesaz2tE0YKhwMeta?.alias || [],
    redirect: portfolio_45feesaz2tE0YKhwMeta?.redirect,
    component: () => import("/codebuild/output/src1726576806/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/portfolio-fees.vue").then(m => m.default || m)
  },
  {
    name: portfolio_45investorswioRW9Z3CHMeta?.name ?? "es-portfolio-investors",
    path: portfolio_45investorswioRW9Z3CHMeta?.path ?? "/es/portfolio-investors",
    meta: portfolio_45investorswioRW9Z3CHMeta || {},
    alias: portfolio_45investorswioRW9Z3CHMeta?.alias || [],
    redirect: portfolio_45investorswioRW9Z3CHMeta?.redirect,
    component: () => import("/codebuild/output/src1726576806/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/portfolio-investors.vue").then(m => m.default || m)
  },
  {
    name: portfolio_45issuers2X4qy36jwmMeta?.name ?? "es-portfolio-issuers",
    path: portfolio_45issuers2X4qy36jwmMeta?.path ?? "/es/portfolio-issuers",
    meta: portfolio_45issuers2X4qy36jwmMeta || {},
    alias: portfolio_45issuers2X4qy36jwmMeta?.alias || [],
    redirect: portfolio_45issuers2X4qy36jwmMeta?.redirect,
    component: () => import("/codebuild/output/src1726576806/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/portfolio-issuers.vue").then(m => m.default || m)
  },
  {
    name: portfolio_45membersxPTIcFu8UvMeta?.name ?? "es-portfolio-members",
    path: portfolio_45membersxPTIcFu8UvMeta?.path ?? "/es/portfolio-members",
    meta: portfolio_45membersxPTIcFu8UvMeta || {},
    alias: portfolio_45membersxPTIcFu8UvMeta?.alias || [],
    redirect: portfolio_45membersxPTIcFu8UvMeta?.redirect,
    component: () => import("/codebuild/output/src1726576806/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/portfolio-members.vue").then(m => m.default || m)
  },
  {
    name: data_45center_45socimitT60tAareIMeta?.name ?? "es-portfolio-news-data-center-socimi",
    path: data_45center_45socimitT60tAareIMeta?.path ?? "/es/portfolio-news/data-center-socimi",
    meta: data_45center_45socimitT60tAareIMeta || {},
    alias: data_45center_45socimitT60tAareIMeta?.alias || [],
    redirect: data_45center_45socimitT60tAareIMeta?.redirect,
    component: () => import("/codebuild/output/src1726576806/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/portfolio-news/data-center-socimi.vue").then(m => m.default || m)
  },
  {
    name: indexy4VX1TPt8yMeta?.name ?? "es-portfolio-news",
    path: indexy4VX1TPt8yMeta?.path ?? "/es/portfolio-news",
    meta: indexy4VX1TPt8yMeta || {},
    alias: indexy4VX1TPt8yMeta?.alias || [],
    redirect: indexy4VX1TPt8yMeta?.redirect,
    component: () => import("/codebuild/output/src1726576806/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/portfolio-news/index.vue").then(m => m.default || m)
  },
  {
    name: transformation_45of_45an_45edav_45into_45a_45reitmRP6YUUCq5Meta?.name ?? "es-portfolio-news-transformation-of-an-edav-into-a-reit",
    path: transformation_45of_45an_45edav_45into_45a_45reitmRP6YUUCq5Meta?.path ?? "/es/portfolio-news/transformation-of-an-edav-into-a-reit",
    meta: transformation_45of_45an_45edav_45into_45a_45reitmRP6YUUCq5Meta || {},
    alias: transformation_45of_45an_45edav_45into_45a_45reitmRP6YUUCq5Meta?.alias || [],
    redirect: transformation_45of_45an_45edav_45into_45a_45reitmRP6YUUCq5Meta?.redirect,
    component: () => import("/codebuild/output/src1726576806/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/portfolio-news/transformation-of-an-edav-into-a-reit.vue").then(m => m.default || m)
  },
  {
    name: portfolio_45partners4bEb11UAEpMeta?.name ?? "es-portfolio-partners",
    path: portfolio_45partners4bEb11UAEpMeta?.path ?? "/es/portfolio-partners",
    meta: portfolio_45partners4bEb11UAEpMeta || {},
    alias: portfolio_45partners4bEb11UAEpMeta?.alias || [],
    redirect: portfolio_45partners4bEb11UAEpMeta?.redirect,
    component: () => import("/codebuild/output/src1726576806/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/portfolio-partners.vue").then(m => m.default || m)
  },
  {
    name: privacyXBWjucdrjlMeta?.name ?? "es-privacy",
    path: privacyXBWjucdrjlMeta?.path ?? "/es/privacy",
    meta: privacyXBWjucdrjlMeta || {},
    alias: privacyXBWjucdrjlMeta?.alias || [],
    redirect: privacyXBWjucdrjlMeta?.redirect,
    component: () => import("/codebuild/output/src1726576806/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/privacy.vue").then(m => m.default || m)
  },
  {
    name: indexX1pLTLGxhDMeta?.name ?? "index",
    path: indexX1pLTLGxhDMeta?.path ?? "/",
    meta: indexX1pLTLGxhDMeta || {},
    alias: indexX1pLTLGxhDMeta?.alias || [],
    redirect: indexX1pLTLGxhDMeta?.redirect,
    component: () => import("/codebuild/output/src1726576806/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/index.vue").then(m => m.default || m)
  },
  {
    name: legal8qcXzlbQRZMeta?.name ?? "legal",
    path: legal8qcXzlbQRZMeta?.path ?? "/legal",
    meta: legal8qcXzlbQRZMeta || {},
    alias: legal8qcXzlbQRZMeta?.alias || [],
    redirect: legal8qcXzlbQRZMeta?.redirect,
    component: () => import("/codebuild/output/src1726576806/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/legal.vue").then(m => m.default || m)
  },
  {
    name: portfolio_45aboutZeMo9Ol6EEMeta?.name ?? "portfolio-about",
    path: portfolio_45aboutZeMo9Ol6EEMeta?.path ?? "/portfolio-about",
    meta: portfolio_45aboutZeMo9Ol6EEMeta || {},
    alias: portfolio_45aboutZeMo9Ol6EEMeta?.alias || [],
    redirect: portfolio_45aboutZeMo9Ol6EEMeta?.redirect,
    component: () => import("/codebuild/output/src1726576806/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/portfolio-about.vue").then(m => m.default || m)
  },
  {
    name: portfolio_45feesOVEmESp3OIMeta?.name ?? "portfolio-fees",
    path: portfolio_45feesOVEmESp3OIMeta?.path ?? "/portfolio-fees",
    meta: portfolio_45feesOVEmESp3OIMeta || {},
    alias: portfolio_45feesOVEmESp3OIMeta?.alias || [],
    redirect: portfolio_45feesOVEmESp3OIMeta?.redirect,
    component: () => import("/codebuild/output/src1726576806/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/portfolio-fees.vue").then(m => m.default || m)
  },
  {
    name: portfolio_45investors8T4TollaT6Meta?.name ?? "portfolio-investors",
    path: portfolio_45investors8T4TollaT6Meta?.path ?? "/portfolio-investors",
    meta: portfolio_45investors8T4TollaT6Meta || {},
    alias: portfolio_45investors8T4TollaT6Meta?.alias || [],
    redirect: portfolio_45investors8T4TollaT6Meta?.redirect,
    component: () => import("/codebuild/output/src1726576806/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/portfolio-investors.vue").then(m => m.default || m)
  },
  {
    name: portfolio_45issuers2vOOEzoM0SMeta?.name ?? "portfolio-issuers",
    path: portfolio_45issuers2vOOEzoM0SMeta?.path ?? "/portfolio-issuers",
    meta: portfolio_45issuers2vOOEzoM0SMeta || {},
    alias: portfolio_45issuers2vOOEzoM0SMeta?.alias || [],
    redirect: portfolio_45issuers2vOOEzoM0SMeta?.redirect,
    component: () => import("/codebuild/output/src1726576806/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/portfolio-issuers.vue").then(m => m.default || m)
  },
  {
    name: portfolio_45membersSlRUARHQ4oMeta?.name ?? "portfolio-members",
    path: portfolio_45membersSlRUARHQ4oMeta?.path ?? "/portfolio-members",
    meta: portfolio_45membersSlRUARHQ4oMeta || {},
    alias: portfolio_45membersSlRUARHQ4oMeta?.alias || [],
    redirect: portfolio_45membersSlRUARHQ4oMeta?.redirect,
    component: () => import("/codebuild/output/src1726576806/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/portfolio-members.vue").then(m => m.default || m)
  },
  {
    name: data_45center_45socimiY7IOG57iYeMeta?.name ?? "portfolio-news-data-center-socimi",
    path: data_45center_45socimiY7IOG57iYeMeta?.path ?? "/portfolio-news/data-center-socimi",
    meta: data_45center_45socimiY7IOG57iYeMeta || {},
    alias: data_45center_45socimiY7IOG57iYeMeta?.alias || [],
    redirect: data_45center_45socimiY7IOG57iYeMeta?.redirect,
    component: () => import("/codebuild/output/src1726576806/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/portfolio-news/data-center-socimi.vue").then(m => m.default || m)
  },
  {
    name: indexLqs3rDBXJNMeta?.name ?? "portfolio-news",
    path: indexLqs3rDBXJNMeta?.path ?? "/portfolio-news",
    meta: indexLqs3rDBXJNMeta || {},
    alias: indexLqs3rDBXJNMeta?.alias || [],
    redirect: indexLqs3rDBXJNMeta?.redirect,
    component: () => import("/codebuild/output/src1726576806/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/portfolio-news/index.vue").then(m => m.default || m)
  },
  {
    name: transformation_45of_45an_45edav_45into_45a_45reit9IZHE2kncGMeta?.name ?? "portfolio-news-transformation-of-an-edav-into-a-reit",
    path: transformation_45of_45an_45edav_45into_45a_45reit9IZHE2kncGMeta?.path ?? "/portfolio-news/transformation-of-an-edav-into-a-reit",
    meta: transformation_45of_45an_45edav_45into_45a_45reit9IZHE2kncGMeta || {},
    alias: transformation_45of_45an_45edav_45into_45a_45reit9IZHE2kncGMeta?.alias || [],
    redirect: transformation_45of_45an_45edav_45into_45a_45reit9IZHE2kncGMeta?.redirect,
    component: () => import("/codebuild/output/src1726576806/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/portfolio-news/transformation-of-an-edav-into-a-reit.vue").then(m => m.default || m)
  },
  {
    name: portfolio_45partnersElFrq8P5HCMeta?.name ?? "portfolio-partners",
    path: portfolio_45partnersElFrq8P5HCMeta?.path ?? "/portfolio-partners",
    meta: portfolio_45partnersElFrq8P5HCMeta || {},
    alias: portfolio_45partnersElFrq8P5HCMeta?.alias || [],
    redirect: portfolio_45partnersElFrq8P5HCMeta?.redirect,
    component: () => import("/codebuild/output/src1726576806/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/portfolio-partners.vue").then(m => m.default || m)
  },
  {
    name: privacyEFVf0vHk48Meta?.name ?? "privacy",
    path: privacyEFVf0vHk48Meta?.path ?? "/privacy",
    meta: privacyEFVf0vHk48Meta || {},
    alias: privacyEFVf0vHk48Meta?.alias || [],
    redirect: privacyEFVf0vHk48Meta?.redirect,
    component: () => import("/codebuild/output/src1726576806/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/privacy.vue").then(m => m.default || m)
  }
]